import Repository from '@/libs/axios'

const flagResource = '/merchant/order/flag'
const orderResource = '/merchant/order?sort=-created_at'

// const defaultPagination = 10

export default {
  createFlag(payload) {
    return Repository.post(`${flagResource}`, payload)
  },
  getOrders() {
    return Repository.get(`${orderResource}`)
  },
}
