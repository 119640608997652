import Repository from '@/libs/axios'
import ListsRepository from '@/libs/customAxios/listsAxios'
// import DashboardRepository from '@/libs/customAxios/dashboardAxios'

const merchantFinanceResource = '/merchant/finance/merchant'
const merchantDashFinanceResource = '/merchant/finance/merchants'

const defaultPagination = 10
export default {
  // Merchant
  getMerchantFinanceDepositList(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ListsRepository.get(`${merchantFinanceResource}?paginate=${pagination}&page=${pageNumber}&sort=-id&${filterQuery}`)
  },
  getMerchantFinanceDepositListNoPagination(filterQuery) {
    return Repository.get(`${merchantFinanceResource}?sort=-id&${filterQuery}&noPagination`)
  },
  updateStatusMerchantFinanceDeposit(payload) {
    return Repository.post(`${merchantFinanceResource}/status-update`, payload)
  },
  approveStatusMerchantFinanceDeposit(payload) {
    return Repository.post(`${merchantFinanceResource}/status-update`, payload)
  },
  rejectMerchantFinanceDeposit(payload) {
    return Repository.post(`${merchantFinanceResource}/reject`, payload)
  },
  getInvoiceRejectionDetails(id) {
    return Repository.get(`${merchantFinanceResource}/${id}/rejection-details`)
  },
  makeInvoicePaymentRequest(id, payload) {
    return Repository.post(`${merchantFinanceResource}-invoice/${id}/payment-request`, payload)
  },
  getInvoiceOrderList(pageNumber = 1, pagination = defaultPagination, id, filterQuery) {
    return ListsRepository.get(`${merchantFinanceResource}-invoice/${id}/orders?paginate=${pagination}&page=${pageNumber}&${filterQuery}`)
  },
  getInvoiceOrderListNoPagination(id, filterquery) {
    return Repository.get(`${merchantFinanceResource}-invoice/${id}/orders?${filterquery}&noPagination`)
  },
  getAllViewOrdersList(id) {
    return ListsRepository.get(`${merchantFinanceResource}/${id}`)
  },
  getInvoiceData(id) {
    return Repository.get(`${merchantFinanceResource}/${id}/print-invoice`)
  },
  getMerchantOverviewFinance(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ListsRepository.get(`${merchantDashFinanceResource}/orders?paginate=${pagination}&page=${pageNumber}&${filterQuery}&sort=-id`)
  },
  getMerchantOverviewFinanceNoPagination(filterQuery) {
    return Repository.get(`${merchantDashFinanceResource}/orders?${filterQuery}&sort=-id&noPagination`)
  },
  getMerchantOverviewFinanceByKey(pageNumber = 1, attribute, key, filterQuery, pagination = defaultPagination) {
    return ListsRepository.get(`${merchantDashFinanceResource}/orders?paginate=${pagination}&page=${pageNumber}&filter[${attribute}]=${key}&${filterQuery}&sort=-id`)
  },
  getMerchantOverviewFinanceByKeyNoPagination(attribute, key, filterQuery) {
    return Repository.get(`${merchantDashFinanceResource}/orders?filter[${attribute}]=${key}&${filterQuery}&sort=-id&noPagination`)
  },
  // getMerchantFinanceDetails(filterQuery) {
  //   return DashboardRepository.get(`${merchantFinanceResource}/dashboard?${filterQuery}`)
  // },
}
