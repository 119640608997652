/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import Repository from '@/libs/axios'

const ticketResource = '/merchant/ticket'

const defaultPagination = 5

export default {
  getTicketTypes() {
    return Repository.get(`${ticketResource}-types`)
  },
  getAllTickets(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return Repository.get(`${ticketResource}?${filterQuery}&page=${pageNumber}&paginate=${pagination}`)
  },
  getAllTicketsNoPagination(filterQuery) {
    return Repository.get(`${ticketResource}?${filterQuery}&noPagination`)
  },
  getTicketById(id) {
    return Repository.get(`${ticketResource}/${id}`)
  },
  getTicketConvos(id) {
    return Repository.get(`${ticketResource}/${id}/convos`)
  },
  createTicket(payload) {
    const formData = new FormData()
    for (const key in payload) {
      formData.append(key, payload[key])
    }
    return Repository.post(`${ticketResource}`, formData)
  },
  updateTicket(id, payload) {
    const formData = new FormData()
    for (const key in payload) {
      formData.append(key, payload[key])
    }
    return Repository.post(`${ticketResource}/${id}/convos`, formData)
  },
}
