import Repository from '@/libs/axios'

export default {
  // getCalculateDeliveryCharge() {
  //  return Repository.post(`${calculateDeliveryCharge}`)
  // },
  getCalculateDeliveryCharge(payload) {
    return Repository.get('merchant/calculator/calculate-delivery-charge', {
      params: payload,
    })
  },
}
