<template>
  <div>
    <b-button
      v-if="showRefreshButton"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-tooltip.hover.v-primary="'Refresh Table'"
      class="custom-button-color mr-1"
      size="sm"
      @click="onClickRefresh()"
    >
      <img
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0ibHVjaWRlIGx1Y2lkZS1yZWZyZXNoLWN3Ij48cGF0aCBkPSJNMyAxMmE5IDkgMCAwIDEgOS05IDkuNzUgOS43NSAwIDAgMSA2Ljc0IDIuNzRMMjEgOCIvPjxwYXRoIGQ9Ik0yMSAzdjVoLTUiLz48cGF0aCBkPSJNMjEgMTJhOSA5IDAgMCAxLTkgOSA5Ljc1IDkuNzUgMCAwIDEtNi43NC0yLjc0TDMgMTYiLz48cGF0aCBkPSJNOCAxNkgzdjUiLz48L3N2Zz4="
      >
    </b-button>
    <b-dropdown
      id="dropdown-right"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      split-class="custom-button-color-blur unable-pointer"
      toggle-class="custom-button-color"
      right
      split
      size="sm"
    >
      <template v-slot:button-content>
        <img
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0ibHVjaWRlIGx1Y2lkZS1kb3dubG9hZCI+PHBhdGggZD0iTTIxIDE1djRhMiAyIDAgMCAxLTIgMkg1YTIgMiAwIDAgMS0yLTJ2LTQiLz48cG9seWxpbmUgcG9pbnRzPSI3IDEwIDEyIDE1IDE3IDEwIi8+PGxpbmUgeDE9IjEyIiB4Mj0iMTIiIHkxPSIxNSIgeTI9IjMiLz48L3N2Zz4="
        >
      </template>
      <b-dropdown-item>
        <JsonExcel
          class="btn p-0"
          :data="items"
          :name="name"
          :fields="json_fieldz"
          type="csv"
          :escape-csv="false"
        >
          <div class="d-flex">
            <img
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiM2NjY2NjYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0ibHVjaWRlIGx1Y2lkZS1maWxlLXgiPjxwYXRoIGQ9Ik0xNC41IDJINmEyIDIgMCAwIDAtMiAydjE2YTIgMiAwIDAgMCAyIDJoMTJhMiAyIDAgMCAwIDItMlY3LjVMMTQuNSAyeiIvPjxwb2x5bGluZSBwb2ludHM9IjE0IDIgMTQgOCAyMCA4Ii8+PGxpbmUgeDE9IjkuNSIgeDI9IjE0LjUiIHkxPSIxMi41IiB5Mj0iMTcuNSIvPjxsaW5lIHgxPSIxNC41IiB4Mj0iOS41IiB5MT0iMTIuNSIgeTI9IjE3LjUiLz48L3N2Zz4="
            >
            <div class="left-name">
              Excel
            </div>
            <div class="right-name">
              (Page)
            </div>
          </div>
        </JsonExcel>
      </b-dropdown-item>
      <b-dropdown-item v-if="fetch">
        <JsonExcel
          class="btn p-0"
          :fetch="fetch"
          :before-generate="__showJsonDownloadAlert"
          :fields="json_fieldz"
          type="csv"
          :escape-csv="false"
          :name="bulkname"
        >
          <div class="d-flex">
            <img
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiM2NjY2NjYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0ibHVjaWRlIGx1Y2lkZS1maWxlLXgtMiI+PHBhdGggZD0iTTQgMjJoMTRhMiAyIDAgMCAwIDItMlY3LjVMMTQuNSAySDZhMiAyIDAgMCAwLTIgMnY0Ii8+PHBhdGggZD0iTTE0IDJ2Nmg2Ii8+PHBhdGggZD0ibTMgMTIuNSA1IDUiLz48cGF0aCBkPSJtOCAxMi41LTUgNSIvPjwvc3ZnPg=="
            >
            <div class="left-name">
              Excel
            </div>
            <div class="right-name">
              (All)
            </div>
          </div>
        </JsonExcel>
      </b-dropdown-item>
      <b-dropdown-item v-if="tableData">
        <JsPdf
          :table-fields="tableFields"
          :table-data="tableData"
          :title="title"
        />
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import {
  BButton, VBTooltip, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import JsPdf from '@/components/PdfDownload/JSPdf.vue'
import Ripple from 'vue-ripple-directive'
import JsonExcel from 'vue-json-excel'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    JsonExcel,
    JsPdf,
  },
  props: {
    fetch: {
      type: Function,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    bulkname: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    // eslint-disable-next-line
    json_fieldz: {
      type: Object,
      required: true,
    },
    tableFields: {
      type: Array,
      default: () => {},
    },
    tableData: {
      type: Array,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    showRefreshButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClickRefresh() {
      this.$emit('refresh')
    },
  },
}
</script>

<style scoped>
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
.custom-button-color:enabled {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
.custom-button-color-blur {
  background-color: #6da5ff !important;
  border-color: #6da5ff !important;
}
.left-name {
  font-weight: bolder;
  margin-left: 5px;
  margin-top: 3px;
  color: #666666;
}
.right-name {
  margin-left: 3px;
  margin-top: 3px;
  color: #666666;
}
.unable-pointer {
  pointer-events: none;
}
</style>
