<template>
  <div>
    <div
      v-if="!rows.length && dataLoading"
      class="text-center p-1"
    >
      <span
        class="spinner-border mr-1"
        role="status"
        aria-hidden="true"
      />
      Fetching Data.....
    </div>
    <div
      v-if="!rows.length && !dataLoading"
      class="text-center p-1"
    >
      There are no records to display
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    dataLoading: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
