// import Repository from '@/libs/axios'
import DashboardRepository from '@/libs/customAxios/dashboardAxios'

const dashboardResource = '/merchant/finance/merchant/dashboard'

// const defaultPagination = 10
export default {

  getAllOrdersStatsFinance(filterQuery) {
    return DashboardRepository.get(`${dashboardResource}/all-orders?${filterQuery}`)
  },
  getPendingDeliveryStatsFinance(filterQuery) {
    return DashboardRepository.get(`${dashboardResource}/pending-delivery?${filterQuery}`)
  },
  getReturnedOrdersStatsFinance(filterQuery) {
    return DashboardRepository.get(`${dashboardResource}/returned-orders?${filterQuery}`)
  },
  getDeliveredOrdersStatsFinance(filterQuery) {
    return DashboardRepository.get(`${dashboardResource}/delivered-orders?${filterQuery}`)
  },
  getToBeInvoicedStatsFinance(filterQuery) {
    return DashboardRepository.get(`${dashboardResource}/to-be-invoiced?${filterQuery}`)
  },
  getPendingDepositStatsFinance(filterQuery) {
    return DashboardRepository.get(`${dashboardResource}/pending-deposit?${filterQuery}`)
  },
  getDepositedStatsFinance(filterQuery) {
    return DashboardRepository.get(`${dashboardResource}/deposited?${filterQuery}`)
  },
  getApprovedStatsFinance(filterQuery) {
    return DashboardRepository.get(`${dashboardResource}/approved?${filterQuery}`)
  },
}
