import Repository from '@/libs/axios'

const merchantResource = '/merchant'

const defaultPagination = 10

export default {
  getMerchantUserListWithFilters(businessId, pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return Repository.get(`${merchantResource}/user?${businessId ? `filter[merchant_business_id]=${businessId}` : ''}&page=${pageNumber}&${filterQuery}&paginate=${pagination}&sort=-id`)
  },
  getMerchantUserDetails(adminId) {
    return Repository.get(`${merchantResource}/user/${adminId}`)
  },
  createMerchantUser(payload) {
    return Repository.post(`${merchantResource}/user`, payload)
  },
  updateMerchantUser(userId, payload) {
    return Repository.put(`${merchantResource}/user/${userId}`, payload)
  },
  merchantUserResetPassword(userId, payload) {
    return Repository.put(`${merchantResource}/user/${userId}/reset-password`, payload)
  },

  getMerchantBusinessList() {
    return Repository.get(`${merchantResource}/business`)
  },
  setBusinessAsDefault(businessId) {
    return Repository.post(`${merchantResource}/business/${businessId}/set-default`)
  },
  getMerchantBusinessDetails(businessId) {
    return Repository.get(`${merchantResource}/business/${businessId}`)
  },
  getMerchantBusinessAddressDetails(businessId) {
    return Repository.get(`${merchantResource}/business/${businessId}/business-address`)
  },
  createMerchantBusiness(payload) {
    return Repository.post(`${merchantResource}/business`, payload)
  },
  updateMerchantBusiness(businessId, payload) {
    return Repository.put(`${merchantResource}/business/${businessId}`, payload)
  },

  getMerchantAddressList(businessId) {
    return Repository.get(`${merchantResource}/business/${businessId}/address`)
  },
  getMerchantAddressDetails(businessId, addressId) {
    return Repository.get(`${merchantResource}/business/${businessId}/address/${addressId}`)
  },
  createMerchantAddress(businessId, payload) {
    return Repository.post(`${merchantResource}/business/${businessId}/address`, payload)
  },
  createMerchantContact(businessId, addressId, payload) {
    return Repository.post(`${merchantResource}/business/${businessId}/address/${addressId}/contact`, payload)
  },
  updateMerchantAddress(businessId, addressId, payload) {
    return Repository.put(`${merchantResource}/business/${businessId}/address/${addressId}`, payload)
  },
  updateMerchantContact(businessId, addressId, contactID, payload) {
    return Repository.put(`${merchantResource}/business/${businessId}/address/${addressId}/contact/${contactID}`, payload)
  },

  getMerchantBusinessSettingsDetails() {
    return Repository.get(`${merchantResource}/setting`)
  },
  updateMerchantBusinessSettings(id, payload) {
    return Repository.put(`${merchantResource}/${id}/setting`, payload)
  },
  getbusinessAddresses(businessId, concat) {
    return Repository.get(`${merchantResource}/business/${businessId}/addresses/list?concat=${concat}`)
  },
  getUserListForDropdown(businessId) {
    return Repository.get(`${merchantResource}/business/${businessId}/users-dropdown/`)
  },
  sendIntergrationRequest(businessId, payload) {
    return Repository.post(`${merchantResource}/business/${businessId}/intergrations/request`, payload)
  },
  getIntergrationById(businessId) {
    return Repository.get(`${merchantResource}/business/${businessId}/intergrations`)
  },
}
