<template>
  <div>
    <JsonExcel
      class="btn p-0"
      :data="items"
      :name="name"
      :fields="json_fieldz"
      type="csv"
      :escape-csv="false"
    >
      <b-button
        v-b-tooltip.hover.v-primary="'Download Excel - Page'"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mr-1"
        size="sm"
      >
        <feather-icon
          icon="DownloadIcon"
        />
      </b-button>
    </JsonExcel>
    <JsonExcel
      class="btn p-0"
      :fetch="fetch"
      :before-generate="__showJsonDownloadAlert"
      :fields="json_fieldz"
      type="csv"
      :escape-csv="false"
      :name="bulkname"
    >
      <b-button
        v-b-tooltip.hover.v-primary="'Download Excel - All'"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mr-1"
        size="sm"
      >
        <mdicon
          name="DownloadMultiple"
          size="15"
        />
      </b-button>
    </JsonExcel>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-tooltip.hover.v-primary="'Refresh Table'"
      variant="outline-primary"
      size="sm"
      @click="onClickRefresh()"
    >
      <feather-icon icon="RefreshCwIcon" />
    </b-button>
  </div>
</template>

<script>
import {
  BButton, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import JsonExcel from 'vue-json-excel'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BButton,
    JsonExcel,
  },
  props: {
    fetch: {
      type: Function,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    bulkname: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    // eslint-disable-next-line
    json_fieldz: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onClickRefresh() {
      this.$emit('refresh')
    },
  },
}
</script>
