import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/merchant-dashboard/',
      },
    },
    {
      path: '/add-new-order',
      name: 'second-page',
      component: () => import('@/views/order-section/create/OrderCreateHome.vue'),
      meta: {
        pageTitle: 'Add New Order',
        breadcrumb: [
          {
            text: 'Add New Order',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/user-guide__trashed/add-new-order/',
      },
    },
    {
      path: '/all-orders',
      name: 'my-orders',
      component: () => import('@/views/allOrders.vue'),
      meta: {
        pageTitle: 'My Orders',
        breadcrumb: [
          {
            text: 'My Orders',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/user-guide__trashed/my-order/',
      },
    },
    {
      path: '/print-pod',
      name: 'print-pod',
      component: () => import('@/components/print/Dispatch.vue'),
      meta: {
        pageTitle: 'Print POD',
        breadcrumb: [
          {
            text: 'Print POD',
            active: true,
          },
        ],
        contentRenderer: 'pdf',
      },
    },
    {
      path: '/merchant-overview',
      name: 'merchant-overview',
      component: () => import('@/views/finance/MerchantFinanceOverview.vue'),
      meta: {
        pageTitle: 'Merchant',
        breadcrumb: [
          {
            text: 'Merchant Overview',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/finance-overview/',
      },
    },
    {
      path: '/my-invoices',
      name: 'my-invoice',
      component: () => import('@/views/finance/MerchantFinance.vue'),
      meta: {
        pageTitle: 'My Invoices',
        breadcrumb: [
          {
            text: 'My Invoices',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/finance-my-invoice/',
      },
    },
    {
      path: '/operations/merchant-finance-invoice/:id',
      name: 'merchant-finance-invoice',
      component: () => import('@/components/FinanceModuleForms/ActionMerchantForms/merchantInvoicePreview.vue'),
      meta: {
        pageTitle: '',
        contentRenderer: 'pdf',
      },
    },
    {
      path: '/client-operations',
      name: 'client-operations',
      component: () => import('@/views/order-section/OrderOperation.vue'),
      meta: {
        pageTitle: 'Merchant Operations',
        breadcrumb: [
          {
            text: 'Merchant Operations',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/operations/',
      },
    },
    {
      path: '/operations/my-invoices/:id',
      name: 'invoice-orders',
      component: () => import('@/components/FinanceModuleForms/ActionMerchantForms/OrderList.vue'),
      meta: {
        pageTitle: 'Orders',
        breadcrumb: [
          {
            text: 'Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('@/views/Notifications.vue'),
      meta: {
        pageTitle: 'Notification',
        breadcrumb: [
          {
            text: 'Notification',
            active: true,
          },
        ],
      },
    },
    {
      path: '/report/finance-report',
      name: 'finance-report',
      component: () => import('@/views/reports/FinanceReport.vue'),
      meta: {
        pageTitle: 'Finance Report',
        breadcrumb: [
          {
            text: 'Finance Report',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/user-guide__trashed/report/',
      },
    },
    {
      path: '/report/return-orders',
      name: 'return-orders',
      component: () => import('@/views/reports/ReturnOrders.vue'),
      meta: {
        pageTitle: 'Return Orders Report',
        breadcrumb: [
          {
            text: 'Return Orders Report',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/user-guide__trashed/report/',
      },
    },
    {
      path: '/report/delivery-orders',
      name: 'delivery-orders',
      component: () => import('@/views/reports/DeliveryOrders.vue'),
      meta: {
        pageTitle: 'Delivery Orders Report',
        breadcrumb: [
          {
            text: 'Delivery Orders Report',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/user-guide__trashed/report/',
      },
    },
    {
      path: '/roles',
      name: 'roles',
      component: () => import('@/views/roles/Roles.vue'),
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'Roles',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/manage/',
      },
    },
    {
      path: '/roles/add',
      name: 'role-add',
      component: () => import('@/components/roles/RoleManagement.vue'),
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'Add',
            active: true,
          },
        ],
      },
    },
    {
      path: '/roles/edit/:roleId',
      name: 'role-edit',
      component: () => import('@/components/roles/RoleManagement.vue'),
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/businesses',
      name: 'businesses',
      component: () => import('@/views/merchant/MerchantBusinessManagement.vue'),
      meta: {
        pageTitle: 'Business',
        breadcrumb: [
          {
            text: 'Businesses',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/manage/',
      },
    },
    {
      path: '/finance/my-rate-card',
      name: 'my-rate-card',
      component: () => import('@/components/rate-card/rateCardForm.vue'),
      meta: {
        pageTitle: 'Finance',
        breadcrumb: [
          {
            text: 'My Rate Card',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/finance-my-rate-card/',
      },
    },
    {
      path: '/businesses/business-details/:businessId',
      name: 'merchant-business-details',
      component: () => import('@/views/merchant/MerchantBusinessDetailsManagement.vue'),
      meta: {
        pageTitle: 'Businesses Details',
        breadcrumb: [
          {
            text: 'Business',
            to: { name: 'businesses' },
          },
          {
            text: 'Businesses Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/merchant/MerchantUserManagement.vue'),
      meta: {
        pageTitle: 'Users',
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/manage/',
      },
    },
    {
      path: '/business-settings',
      name: 'business-settings',
      component: () => import('@/views/BusinessSettings.vue'),
      meta: {
        pageTitle: 'Business Settings',
        breadcrumb: [
          {
            text: 'Business Settings',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/user-guide__trashed/settings/',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        noAuth: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/forgot-password/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        noAuth: true,
      },
    },
    {
      path: '/reset-password/:email/:token',
      name: 'reset-password',
      component: () => import('@/views/forgot-password/ResetPassword.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        noAuth: true,
      },
    },
    {
      path: '/new-merchant',
      name: 'merchant-registration',
      component: () => import('@/views/self-register-merchant/SelfRegisterMerchant.vue'),
      meta: {
        layout: 'full',
        noAuth: true,
      },
    },
    {
      path: '/tickets',
      name: 'tickets',
      component: () => import('@/views/tickets/Tickets.vue'),
      meta: {
        pageTitle: 'Tickets',
        breadcrumb: [
          {
            text: 'Tickets',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs/tickets/',
      },
    },
    {
      path: '/tickets/:id',
      name: 'ticket-details',
      component: () => import('@/views/tickets/TicketDetails.vue'),
      meta: {
        pageTitle: 'Tickets',
        breadcrumb: [
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/waybill-request',
      name: 'waybill-request',
      component: () => import('@/views/waybill-request/WaybillRequest.vue'),
      meta: {
        pageTitle: 'Waybill Request',
        breadcrumb: [
          {
            text: 'Waybill Request',
            active: true,
          },
        ],
        help: 'https://curfox.com/docs-category/merchant-portal/',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
