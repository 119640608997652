import Repository from '@/libs/axios'
import ListsRepository from '@/libs/customAxios/listsAxios'

const resource = '/merchant/resource'
const roleResource = '/merchant/roles'
const permissionResource = '/merchant/permissions'
const DropdownResource = '/merchant/resource'

export default {
  getStateList() {
    return Repository.get(`${resource}/state-list`)
  },
  getStateDropdownList(id) {
    return Repository.get(`${resource}/state-list?filter[country_id]=${id}&noPagination`)
  },
  getCountryList() {
    return Repository.get(`${resource}/country-list`)
  },
  getCityList(stateId = '') {
    return ListsRepository.get(`${resource}/city-list?${stateId ? `state_id=${stateId}` : ''}&noPagination`)
  },
  getAutoCityDropdownList(filter) {
    return ListsRepository.get(`${resource}/city-list?filter[name]=${filter}&noPagination&verify=true`)
  },
  getCityListDropdownList() {
    return ListsRepository.get(`${resource}/city-list?noPaginationNoFilter`)
  },
  getCityDropdownListById(filter, id) {
    return ListsRepository.get(`${resource}/city-list?filter[name]=${filter}&filter[id]=${id}&noPagination`)
  },
  getFlagList() {
    return Repository.get(`${resource}/flag-list?noPagination`)
  },
  getBusinessList() {
    return Repository.get(`${resource}/business-list`)
  },
  getMerchantBusinessNoPagination() {
    return Repository.get('/merchant/business?noPagination')
  },
  getRoleDetails() {
    return Repository.get(`${roleResource}`)
  },
  getPermissionTree() {
    return Repository.get(`${permissionResource}`)
  },
  getReasonListForDropDown(key = '') {
    return Repository.get(`${DropdownResource}/reason-list?status_key=${key}`)
  },
  getZoneListDropdown() {
    return Repository.get(`${DropdownResource}/zone-list`)
  },
  getAllCityList() {
    return ListsRepository.get('/city?noPagination')
  },
  getExcelCitylist() {

  },
  fetchExcelTemplate(url) {
    return Repository.head(url)
  },
}
