import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { TreeViewPlugin } from '@syncfusion/ej2-vue-navigations'
import VueSweetalert2 from 'vue-sweetalert2'
import LetItSnow from 'vue-let-it-snow'
import VueExcelEditor from 'vue-excel-editor'
import VueIdentifyNetwork from 'vue-identify-network'
import mdiVue from 'mdi-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import * as mdijs from '@mdi/js'
import moment from 'moment'
import authController from '@/router/auth_controller'
import router from './router'
import store from './store'
import App from './App.vue'
import './theme-setup'

// Global Components
import './global-components'

import ReportTableButtonsVue from './components/ReportTableUtils/ReportButtons/ReportTableButtons.vue'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vee-validation'
import TableButtons from './components/TableButtons/TableButtons.vue'
import TableDataFetching from './components/TableDataFetching.vue'

Vue.mixin({
  methods: {
    // eslint-disable-next-line no-underscore-dangle
    __dateTimeFormatter(date) {
      if (date) {
        return moment.utc(date).local().format('DD/MM/YYYY HH:mm')
      }
      return ''
    },
    // eslint-disable-next-line no-underscore-dangle
    __dateTimeFormatterPassedTime(date) {
      const time = new Date(date)
      const now = new Date()
      const diff = now - time
      const seconds = Math.floor(diff / 1000)
      const minutes = Math.floor(seconds / 60)
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)
      const months = Math.floor(days / 30)
      const years = Math.floor(months / 12)
      if (seconds < 60) return `${seconds} seconds ago`
      if (minutes < 60) return `${minutes} minutes ago`
      if (hours < 24) return `${hours} hours ago`
      if (days < 30) return `${days} days ago`
      if (months < 12) return `${months} months ago`
      return `${years} years ago`
    },
    // eslint-disable-next-line no-underscore-dangle
    __numberWithCommas(x, decimalPoints = false) {
      if (x) {
        if (decimalPoints) {
          // eslint-disable-next-line no-param-reassign
          x = parseFloat(x).toFixed(2)
          // eslint-disable-next-line no-param-reassign
          x = x.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 })
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return decimalPoints ? '0.00' : '0'
    },
    // eslint-disable-next-line no-underscore-dangle
    __formatNumber(value) {
      if (value || value === 0) {
        // Convert to integer and use toLocaleString to add commas
        // eslint-disable-next-line radix
        return parseInt(value).toLocaleString('en')
      }
      return '0'
    },
    // eslint-disable-next-line no-underscore-dangle
    __focusInputField(name) {
      const formElements = this.$refs.formbuilder.$children[0].$children
      formElements.forEach(formElement => {
        if (formElement.name === name) {
          formElement.$children[0].$children[0].$el.focus()
        }
      })
    },
    // eslint-disable-next-line no-underscore-dangle
    __refineDateQuery(filterQuery, searchKeys) {
      searchKeys.forEach(searchKey => {
        if (filterQuery.includes(searchKey)) {
          const startIndex = filterQuery.indexOf(searchKey)
          let singleDateSelected = true
          try {
            if (filterQuery.substring(startIndex, startIndex + searchKey.length + 17).includes('to')) {
              singleDateSelected = false
            }
          } catch (e) {
            singleDateSelected = true
          }
          if (singleDateSelected) {
            const dateQuery = filterQuery.substring(startIndex, startIndex + searchKey.length + 11)
            // eslint-disable-next-line no-param-reassign
            filterQuery = filterQuery.replaceAll(dateQuery, '')
            const date = dateQuery.split('=')[1]
            // eslint-disable-next-line no-param-reassign
            filterQuery += `&${searchKey}=${date}%20to%20${date}`
          }
        }
      })
      return filterQuery
    },
    // eslint-disable-next-line no-underscore-dangle
    __preserveTabOnRefresh(refKey, sessionKey) {
      this.$refs[refKey].currentTab = Number(sessionStorage.getItem(sessionKey)) || 0
      sessionStorage.setItem(sessionKey, '')
      window.onbeforeunload = () => {
        sessionStorage.setItem(sessionKey, this.$refs[refKey].currentTab)
      }
    },
    // eslint-disable-next-line no-underscore-dangle
    __toggleDisableInput(name, disabled) {
      const formElements = this.$refs.formbuilder.$children[0].$children
      formElements.forEach(formElement => {
        if (formElement.name === name) {
          // eslint-disable-next-line no-param-reassign
          formElement.$children[0].$children[0].$el.disabled = disabled
        }
      })
    },
    // eslint-disable-next-line no-underscore-dangle
    __showSweetAlert(title, message) {
      this.$swal({
        title,
        text: message,
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'Okay',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
    },
    // eslint-disable-next-line no-underscore-dangle
    __showJsonDownloadAlert() {
      // eslint-disable-next-line no-underscore-dangle
      this.__showSweetAlert('Downloading Data', 'This process may take some time. You may close this window and continue working')
    },
    // eslint-disable-next-line no-underscore-dangle
    __clearCache() {
      const feedbackRecord = localStorage.getItem('feedback_asked')
      const zoomVal = localStorage.getItem('browser_zoom')
      localStorage.clear()
      localStorage.setItem('feedback_asked', feedbackRecord)
      localStorage.setItem('browser_zoom', zoomVal)
    },
  },
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(TreeViewPlugin)

Vue.use(VueExcelEditor)
// Composition API
Vue.use(VueCompositionAPI)

// Sweet Alert
Vue.use(VueSweetalert2)

Vue.use(LetItSnow)

Vue.use(mdiVue, {
  icons: mdijs,
})

Vue.use(VueIdentifyNetwork)

// vue component registerd globally
Vue.component('TableButtons', TableButtons)
Vue.component('TableDataFetching', TableDataFetching)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// vue component registerd globally
Vue.component('ReportTableButtons', ReportTableButtonsVue)

Vue.config.productionTip = false
authController.init(router, store)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
