const psl = require('psl')

let host = 'mng'

if (`${process.env.VUE_APP_IS_TENANT}` === '1') {
  const url = window.location.host
  if (url.indexOf(`${process.env.VUE_APP_DOMAIN}`) !== -1) {
    // eslint-disable-next-line prefer-destructuring
    host = url.split('.')[0]
  } else {
    const parsed = psl.parse(url)
    // eslint-disable-next-line
    host = parsed.sld
  }
}
// eslint-disable-next-line import/prefer-default-export
export const apiUrl = process.env.VUE_APP_API_URL
// service oriented server implementation
export const apiDashboardUrl = process.env.VUE_APP_API_DASHBOARD_URL
export const apiOperationUrl = process.env.VUE_APP_API_OPERATION_URL
export const apiListsUrl = process.env.VUE_APP_API_LISTS_URL
export const apiReportsUrl = process.env.VUE_APP_API_REPORTS_URL
//
export const bucketUrl = process.env.VUE_APP_BUCKET_URL
export const tenant = host
export const defaultCompanyLogo = process.env.VUE_APP_BUCKET_URL + process.env.VUE_APP_DEFAULT_COMPANY_LOGO_PATH
export const defaultExcelTemplate = process.env.VUE_APP_BUCKET_URL + process.env.VUE_APP_DEFAULT_EXCEL_TEMPLATE_PATH
export const defaultSecondPhoneNumberExcelTemplate = process.env.VUE_APP_BUCKET_URL + process.env.VUE_APP_DEFAULT_SECONDARY_PHONE_NUMBER_EXCEL_TEMPLATE_PATH
export const appEnv = process.env.VUE_APP_ENV

// KPI Dashboard API
// export const kpiDashboardUrl = process.env.VUE_APP_KPI_API_URL
