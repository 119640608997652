import Repository from '@/libs/axios'
import OperationRepository from '@/libs/customAxios/operationAxios'
import ListsRepository from '@/libs/customAxios/listsAxios'

const resource = '/merchant/order'
const defaultPagination = 20

export default {
  getOrderList(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ListsRepository.get(`${resource}?page=${pageNumber}&${filterQuery}&paginate=${pagination}&sort=-id`)
  },
  getOrderListNoPagination(filterQuery) {
    return Repository.get(`${resource}?&${filterQuery}&sort=-id&noPagination`)
  },
  getOrderListbyKey(pageNumber = 1, key, filterQuery, pagination = defaultPagination) {
    return ListsRepository.get(`${resource}?page=${pageNumber}&filter[status]=${key}&${filterQuery}&paginate=${pagination}&sort=-id`)
  },
  getOrderListbyKeyNoPagination(key, filterQuery) {
    return Repository.get(`${resource}?filter[status]=${key}&${filterQuery}&sort=-id&noPagination`)
  },
  getOrderDetails(id) {
    return Repository.get(`${resource}/${id}`)
  },
  getOrderTimeLine(id) {
    return Repository.get(`${resource}/${id}/time-line`)
  },
  getOrderInvoiceTimeLine(id) {
    return Repository.get(`${resource}/${id}/invoice-time-line`)
  },
  getOrderSummery() {
    return Repository.get(`${resource}/summery`)
  },
  getOrderStatusList() {
    return Repository.get(`${resource}/status-list`)
  },
  getNonPickupRequestOrderCount() {
    return Repository.get(`${resource}/non-pickup-request-count`)
  },
  createSingleOrder(payload) {
    return Repository.post(`${resource}/single`, payload)
  },
  createBulkOrder(payload) {
    return Repository.post(`${resource}/bulk`, payload)
  },
  createBulkExcelOrder(payload) {
    return Repository.post(`${resource}/bulk-excel`, payload)
  },
  deleteOrder(payload) {
    return Repository.delete(`${resource}/bulk-destroy`, {
      params: {
        waybill_numbers: payload,
      },
    })
  },

  getKeyListForOrders(paylaod) {
    return Repository.get(`${resource}/order-status-key`, {
      params: {
        waybills: paylaod,
      },
    })
  },

  getStatusList() {
    return Repository.get('/merchant/status/merchant')
  },
  getOrderListByKey(key, pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return ListsRepository.get(`/merchant/order/operation?status_key=${key}&paginate=${pagination}&page=${pageNumber}&${filterQuery}&sort=-id`)
  },
  getOrderListByKeyNoPagination(key, filterQuery) {
    return Repository.get(`/merchant/order/operation?status_key=${key}&${filterQuery}&sort=-id&noPagination`)
  },
  updateOrderStatus(payload) {
    return OperationRepository.post('/merchant/order/status-update', payload)
  },
  getPODOrdersByWaybill(waybill) {
    return Repository.get(`/merchant/order/${waybill}/print-pod`)
  },
  getPODOrdersByWaybills(waybills) {
    return Repository.get('/merchant/order/print-pod-bulk', {
      params: {
        waybill_number: waybills,
      },
    })
  },
  getOrderFlagDetailsById(id) {
    return Repository.get(`/merchant/order/${id}/flag`)
  },

  editDraftOrder(payload) {
    return Repository.put('/merchant/order/draft-edit', payload)
  },
}
