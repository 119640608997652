import Repository from '@/libs/axios'

const layoutSettingResource = '/merchant/settings/layout'

export default {
  getWaybillLayout() {
    return Repository.get(`${layoutSettingResource}/waybill`)
  },
  getActiveWaybillLayout() {
    return Repository.get(`${layoutSettingResource}/waybill/active`)
  },
  getWaybillLayoutDetail(id) {
    return Repository.get(`${layoutSettingResource}/waybill/${id}`)
  },
}
