import Repository from '@/libs/axios'

const orderSettingsResource = '/merchant/settings/order'

export default {
  getOrderSettingsResource() {
    return Repository.get(`${orderSettingsResource}`)
  },
  updateOrderSettingsResource(payload) {
    return Repository.put(`${orderSettingsResource}`, payload)
  },
}
