import Repository from '@/libs/axios'

const notificationResource = '/merchant/notifications'

const defaultPagination = 5

export default {
  getAllNotifications(pageNumber = 1, filterQuery, pagination = defaultPagination) {
    return Repository.get(`${notificationResource}?${filterQuery}&page=${pageNumber}&paginate=${pagination}`)
  },
  getAllNotificationsNoPagination(filterQuery) {
    return Repository.get(`${notificationResource}?${filterQuery}&noPagination`)
  },
  markAsReadAll() {
    return Repository.put(`${notificationResource}/mark-as-read`)
  },
  markAsReadSingle(id) {
    return Repository.put(`${notificationResource}/mark-as-read/${id}`)
  },
  deleteNotification(payload) {
    return Repository.delete(`${notificationResource}`, {
      params: payload,
    })
  },
}
