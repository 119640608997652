import Repository from '@/libs/axios'

// const resource = '/merchant/resource'

export default {
  getCountryList() {
    return Repository.get('country?noPagination')
  },
  getStateListByID(countryID) {
    return Repository.get(`/country/${countryID}/state`)
  },
  getCityListByID(stateID) {
    return Repository.get(`/state/${stateID}/city`)
  },
  setMerchant(payload) {
    return Repository.post('/merchant/registration', payload)
  },
}
