import Repository from '@/libs/axios'

const bannerAuthResource = '/merchant/notifications/active-banners-auth'
const bannerNoAuthResource = '/notifications/active-banners-no-auths'

export default {
  getActiveBannerAuth() {
    return Repository.get(`${bannerAuthResource}`)
  },
  getActiveBannerNoAuth() {
    return Repository.get(`${bannerNoAuthResource}`)
  },
}
