// import Repository from '@/libs/axios'
import ReportRepository from '@/libs/customAxios/reportsAxios'

const reportFinanceResource = '/merchant/report/finance-report'
const deliveryOrdersResource = '/merchant/report/delivery-orders-report'
const returnOrdersResource = '/merchant/report/return-orders-report'

const defaultPagination = 10
export default {
  getOrderStatsFinance(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return ReportRepository.get(`${reportFinanceResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}`)
  },
  getOrderStatsFinanceNoPagination(filterQuery, sort) {
    return ReportRepository.get(`${reportFinanceResource}?sort=${sort}&${filterQuery}&noPagination`)
  },
  getDeliveryOrdersFinance(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return ReportRepository.get(`${deliveryOrdersResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}`)
  },
  getDeliveryOrdersFinanceNoPagination(filterQuery, sort) {
    return ReportRepository.get(`${deliveryOrdersResource}?sort=${sort}&${filterQuery}&noPagination`)
  },
  getReturnOrdersFinance(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return ReportRepository.get(`${returnOrdersResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}`)
  },
  getReturnOrdersFinanceNoPagination(filterQuery, sort) {
    return ReportRepository.get(`${returnOrdersResource}?sort=${sort}&${filterQuery}&noPagination`)
  },
}
