// import Repository from '@/libs/axios'
import DashboardRepository from '@/libs/customAxios/dashboardAxios'
// import KPIDashboardRepository from '@/libs/customAxios/kpiAxios'

const orderStatsFinanceResource = '/merchant/dashboard/order-statistics'
const financialStatFinanceResource = '/merchant/dashboard/financial-statistics'
const orderMonthlyOrdersFinanceResource = '/merchant/dashboard/monthly-orders'
const orderMonthlyEarningsFinanceResource = '/merchant/dashboard/cod-income'
const orderCODReceivableFinanceResource = '/merchant/dashboard/cod-receivable'
const orderToBEInvoicesFinanceResource = '/merchant/dashboard/to-be-invoice'
const orderMerchantOnBoardingFinanceResource = '/merchant/dashboard/merchant-on-boarding'
const orderStatusStatisticsFinanceResource = '/merchant/dashboard/status-statistics'
const monthlyDeliveryChargeFinanceResource = '/merchant/dashboard/delivery-charges'
const citywiseStatsResource = '/merchant/dashboard/city-wise-stats'
// const groupedBarChartResource = '/kpi_results'

// const defaultPagination = 10
export default {
  getOrderStatsFinance() {
    return DashboardRepository.get(`${orderStatsFinanceResource}`)
  },
  getFinancialStatFinance() {
    return DashboardRepository.get(`${financialStatFinanceResource}`)
  },
  getMonthlyOrdersFinance() {
    return DashboardRepository.get(`${orderMonthlyOrdersFinanceResource}`)
  },
  getMonthlyEarningsFinance() {
    return DashboardRepository.get(`${orderMonthlyEarningsFinanceResource}`)
  },
  getCODRecivableFinance() {
    return DashboardRepository.get(`${orderCODReceivableFinanceResource}`)
  },
  getToBEInvoicesFinance() {
    return DashboardRepository.get(`${orderToBEInvoicesFinanceResource}`)
  },
  getMerchantOnBoardingFinance() {
    return DashboardRepository.get(`${orderMerchantOnBoardingFinanceResource}`)
  },
  getmonthlyDeliveryChargeFinance() {
    return DashboardRepository.get(`${monthlyDeliveryChargeFinanceResource}`)
  },
  getStatusStatisticsFinance() {
    return DashboardRepository.get(`${orderStatusStatisticsFinanceResource}`)
  },
  getCityWiseStats() {
    return DashboardRepository.get(`${citywiseStatsResource}`)
  },
  // getGroupedBarChart() {
  //   return KPIDashboardRepository.get(`${groupedBarChartResource}`)
  // },
}
