import Repository from '@/libs/axios'

const waybillRequests = '/merchant/waybill'
const merchantResource = '/merchant'
const defaultPagination = 10

export default {
  getWaybillRequestList(pageNumber = 1, pagination = defaultPagination) {
    return Repository.get(`${waybillRequests}?paginate=${pagination}&page=${pageNumber}`)
  },
  sendWaybillRequestReport(payload) {
    return Repository.post(`${waybillRequests}`, payload)
  },
  getWaybillRequestListNoPagination(filterQuery) {
    return Repository.get(`${waybillRequests}?${filterQuery}&noPagination`)
  },
  getMerchantBusinessDetails() {
    return Repository.get(`${merchantResource}/setting`)
  },
}
