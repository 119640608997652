import { RepositoryFactory } from '@/repository/RepositoryFactory'

const colorThemeRepository = RepositoryFactory.get('colorTheme')

async function applyGlobalTheme() {
  const { data } = (await colorThemeRepository.getColor())
  const {
    gradientColor1,
    gradientColor2,
    gradientDirection,
  } = data

  // Update the CSS variables globally
  document.documentElement.style.setProperty('--gradientColor1', gradientColor1)
  document.documentElement.style.setProperty('--gradientColor2', gradientColor2)
  document.documentElement.style.setProperty('--gradientDirection', gradientDirection)
  document.documentElement.style.setProperty('--primary', gradientColor1)
}
applyGlobalTheme() // Call the function to apply the global theme when this file is imported
// Export the function if needed
export default { applyGlobalTheme }
