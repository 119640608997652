import authRepository from './authRepository'
import orderRepository from './orderRepository'
import resourceRepository from './resourceRepository'
import roleRepository from './roleRepository'
import dashBoardRepository from './dashBoardRepository'
import reportRepository from './reportRepository'
import financeRepository from './financeRepository'
import merchantRepository from './merchantRepository'
import businessSettingRepository from './businessSettingRepository'
import orderSettingsRepository from './orderSettingsRepository'
import merchantSelfRegister from './merchantSelfRegister'
import remarkRepository from './remarkRepository'
import flagRepository from './flagRepository'
import notificationRepository from './notificationRepository'
import layoutSettingRepository from './layoutSettingRepository'
import cacheRepository from './cacheRepository'
import ticketRepository from './ticketRepository'
import feedbackRepository from './feedbackRepository'
import waybillRequestRepository from './waybillRequestRepository'
import calculatorRepository from './calculatorRepository'
import merchantSettingsRepository from './merchantSettingsRepository'
import alertRepository from './alertRepository'
import colorThemeRepository from './ThemeColorRepository'
import waybillSettingRepository from './waybillSettingRepository'
import bannerRepository from './bannerRepository'
import overviewDashBoardRepository from './overviewDashBoardRepository'

const repositories = {
  auth: authRepository,
  order: orderRepository,
  resource: resourceRepository,
  role: roleRepository,
  dashboard: dashBoardRepository,
  report: reportRepository,
  finance: financeRepository,
  merchant: merchantRepository,
  businessSettings: businessSettingRepository,
  notification: notificationRepository,
  orderSetting: orderSettingsRepository,
  SelfRegister: merchantSelfRegister,
  remark: remarkRepository,
  flag: flagRepository,
  layout: layoutSettingRepository,
  cache: cacheRepository,
  ticket: ticketRepository,
  feedback: feedbackRepository,
  waybill: waybillRequestRepository,
  calculator: calculatorRepository,
  merchantSetting: merchantSettingsRepository,
  alert: alertRepository,
  colorTheme: colorThemeRepository,
  waybillSetting: waybillSettingRepository,
  banner: bannerRepository,
  overviewdashboard: overviewDashBoardRepository,
}

// eslint-disable-next-line import/prefer-default-export
export const RepositoryFactory = {
  get: name => repositories[name],
}
