import Repository from '@/libs/axios'

const roleResource = '/merchant/roles'

const defaultPagination = 10

export default {
  getRoleList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${roleResource}?paginate=${pagination}&page=${pageNumber}&sort=${sort}&${filterQuery}`)
  },
  getRoleListforDropdown() {
    return Repository.get(`${roleResource}?noPagination`)
  },
  getRoleDetails(id) {
    return Repository.get(`${roleResource}/${id}`)
  },
  createRole(payload) {
    return Repository.post(`${roleResource}`, payload)
  },
  updateRole(id, payload) {
    return Repository.put(`${roleResource}/${id}`, payload)
  },
}
