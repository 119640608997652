import Repository from '@/libs/axios'

const resource = '/merchant/setting'
const noAuthresource = '/settings/business'
const rateCard = '/merchant/rate-card'

export default {
  getMerchantSettigs() {
    return Repository.get(`${resource}`)
  },
  getBusinessSettigs() {
    return Repository.get(`${noAuthresource}`)
  },
  setMerchantSettigs(payload) {
    return Repository.put(`${resource}`, payload)
  },
  getMerchantRateCard() {
    return Repository.get(`${rateCard}`)
  },
}
