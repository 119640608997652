import Repository from '@/libs/axios'

const feedbackResource = '/merchant/feedback'

export default {
  getFeedbackSetting() {
    return Repository.get(`${feedbackResource}/setting`)
  },
  createFeedback(payload) {
    return Repository.post(`${feedbackResource}`, payload)
  },
}
